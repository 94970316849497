<template>
  <div class="brand_ordering mt-4">
    <div class="brand_ordering__header">
      <div
        class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
        @click="onUpdate(brands)"
      >
        <span class="text-base text-primary">
          Update
        </span>
      </div>
    </div>
    <draggable v-model="brands">
      <transition-group
        type="transition"
        name="flip-list"
        group="description"
        ghost-class="ghost"
      >
        <div
          v-for="(brand, i) in brands"
          :key="brand.id"
          class="brand_ordering__item mb-3"
        >
          <cld-image
            :cloud-name="cldUpload.cldName.value"
            :public-id="brand.brand_logo.public_id"
            class="brand_ordering__item__logo"
          />
          <div class="brand_ordering__item__name">
            {{ brand.brand_name }}
          </div>
          <div class="brand_ordering__item__order">ลำดับความสำคัญ {{ i + 1 }}</div>
        </div>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import { reactive } from '@vue/composition-api'
import draggable from 'vuedraggable'
import { CldImage, CldTransformation } from 'cloudinary-vue'
import useCrud from '@/use/useCrud'
import useCldUpload from '@/use/useCldUpload'
import useNotify from '@/use/useNotify'
import sortBy from 'lodash/sortBy'

export default {
  name: 'BrandOrdering',
  components: {
    draggable,
    CldImage,
    CldTransformation,
  },
  setup(props, ctx) {
    const crudFunction = useCrud(ctx, 'brand')
    const brands = reactive(sortBy(crudFunction.result.value, 'priority'))
    const cldUpload = useCldUpload(ctx)
    const { success: notifySuccess, error: notifyError } = useNotify(ctx)

    const onUpdate = async (brands) => {
      ctx.root.$vs.loading()
      try {
        await ctx.root.$store.dispatch('brand/updateItemPriority', brands)
        ctx.root.$vs.loading.close()
        notifySuccess({ text: 'แก้ไขลำดับสำเร็จ' })
      } catch (error) {
        ctx.root.$vs.loading.close()
        notifyError({ text: JSON.stringify(error) })
      }
    }

    return {
      brands,
      onUpdate,
      cldUpload,
    }
  },
}
</script>

<style lang="scss">
.brand_ordering {
  &__header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__item {
    display: grid;
    grid-template-columns: 150px 1fr max-content;
    grid-column-gap: 10px;
    cursor: move;
    padding: 20px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
    background-color: rgb(255, 255, 255);
    border-radius: 4px;

    &__logo {
      height: 50px;
      width: 120px;

      img {
        height: 100%;
        width: 100%;
        -o-object-fit: contain;
        object-fit: contain;
      }
    }

    &__name {
      align-self: center;
    }

    &__order {
      align-self: center;
    }
  }
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
