<template>
  <div class="data-list-container">
    <vs-tabs>
      <vs-tab label="Brand List">
        <BrandListView />
      </vs-tab>
      <vs-tab label="Brand Ordering">
        <BrandOrdering />
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
import BrandListView from './BrandListView'
import BrandOrdering from './BrandOrdering'

export default {
  components: {
    BrandListView,
    BrandOrdering
  },
}
</script>
