<template>
  <div>
    <PopupLanguage
      v-model="isPopupDuplicateActive"
      :hiddenLang="hiddenLang"
      :on-click-btn="handlerDuplicateContent"
      name="property-asset-project-create"
    />
    <div 
      id="lh-table" 
      class="data-list-container">
      <custom-table
        ref="table"
        :max-items="itemsPerPage"
        :data="items"
        :searchKeys="searchKeys"
        pagination
        search
      >
        <div 
          slot="header" 
          class="flex items-center flex-grow justify-between">
          <AddNewContent
            :allowLangs="['th']"
            :show-popup="false"
            name="property-brand-create"
          />
          <LhDropdownPerPage 
            :items="items" 
            :items-per-page="itemsPerPage" 
            :current-page="currentPage" 
            :queried-items="queriedItems"
            @click="(page) => itemsPerPage = page"
          /> 
        </div>

        <template slot="thead">
          <vs-th sort-key="logo">Logo</vs-th>
          <vs-th sort-key="brand_name">ชื่อแบรนด์</vs-th>
          <vs-th sort-key="brand_concept">Concept</vs-th>
          <vs-th sort-key="updated_at">วันที่แก้ไขล่าสุด</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr 
              v-for="(tr, indextr) in data" 
              :data="tr" 
              :key="indextr">
              <td class="td custom-table--td" @click.stop="editData(tr.id)" width="20%">
                <cld-image
                  :cloud-name="cldUpload.cldName.value"
                  :public-id="get(tr, ['brand_logo', 'public_id'], null)"
                  class="brand-logo"
                />
              </td>
              <td
                class="td custom-table--td brand-name"
                @click.stop="editData(tr.id)" 
                width="15%" 
              >{{ tr.brand_name }}</td>
              <td
                class="td custom-table--td brand-concept"
                @click.stop="editData(tr.id)" 
                width="40%" 
              >{{ tr.brand_concept }}</td>
              <td
                class="td custom-table--td"
                width="15%"
              ><p class="brand-priority">
                {{ updatedAtDatetimeFormat(tr.updated_at) }}
              </p></td>
              <vs-td class="whitespace-no-wrap cursor-default">
                <div class="flex space-x-2">
                  <feather-icon
                    class="cursor-pointer"
                    icon="CopyIcon"
                    svg-classes="w-5 h-5 hover:text-primary stroke-current"
                    @click.stop="duplicateContent(tr)"
                  />
                  <router-link
                    :to="{ name: `${routePrefix}-edit`, params: { id: tr.id }}"
                    class="pt-2 color-text-link"
                  >
                    <feather-icon
                      icon="EditIcon"
                      svg-classes="w-5 h-5 hover:text-primary stroke-current"
                    />
                  </router-link>
                  <feather-icon
                    class="cursor-pointer"
                    icon="TrashIcon"
                    svg-classes="w-5 h-5 hover:text-danger stroke-current"
                    @click.stop="deleteData(tr.id)"
                  />
                </div>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script>
import { computed, ref, onMounted } from '@vue/composition-api'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { CldImage, CldTransformation } from 'cloudinary-vue'
import AddNewContent from '@/components/AddNewContent'
import PopupLanguage from '@/components/PopupLanguage'
import useBrandUtil from '@/use/useBrandUtil'
import useDatepicker from '@/use/useDatepicker'
import useCldUpload from '@/use/useCldUpload'
import useCrud from '@/use/useCrud'
import LhDropdownPerPage from '@/components/LhDropdownPerPage'
import CustomTable from '@/components/CustomTable'

export default {
  name: 'BrandListView',
  components: {
    PopupLanguage,
    AddNewContent,
    CldImage,
    CldTransformation,
    LhDropdownPerPage,
    CustomTable
  },
  setup(props, ctx) {
    const crudFunction = useCrud(ctx, 'brand')
    const cldUpload = useCldUpload(ctx)
    const { updatedAtDatetimeFormat } = useDatepicker()
    const { formData, prepareFormData, serializeData } = useBrandUtil(ctx)

    crudFunction.routePrefix.value = 'property-brand'

    const items = computed(() => {
      return crudFunction.result.value
    })

    const hiddenLang = ref([])
    const allItems = ref([])

    onMounted(async () => {
      allItems.value = await ctx.root.$store.dispatch(
        'brand/fetchAll',
      )
    })

    const handlerDuplicateContentFromCRUD = async (
      formData,
      prepareFormData,
      langToDuplicationContent,
    ) => {
      ctx.root.$vs.loading()
      await ctx.root.$store.dispatch('locale/changeLang', langToDuplicationContent)
      const result = await ctx.root.$store.dispatch(
        'brand/fetchOne',
        crudFunction.contentDuplicateId.value,
      )
      await prepareFormData(result)

      const activeUser = ctx.root.$store.state.AppActiveUser
      const email = get(activeUser, 'email', '')
      // add user created by
      formData.created_by = email
      formData.updated_by = email

      const body = serializeData(formData)

      const resultDuplicate = await ctx.root.$store.dispatch('brand/addItem', {
        ...body,
        id: null,
      })
      crudFunction.isPopupDuplicateActive.value = false
      await ctx.root.$vs.loading.close()
      await ctx.root.$router.push({
        name: `${crudFunction.routePrefix.value}-edit`,
        params: {
          id: resultDuplicate.id,
        },
      })
    }

    const handlerDuplicateContent = (langToDuplicationContent) => {
      handlerDuplicateContentFromCRUD(formData, prepareFormData, langToDuplicationContent)
    }

    const editData = (id) => {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      ctx.root.$router.push({
        name: `${crudFunction.routePrefix.value}-edit`,
        params: { id },
      })
    }

    const duplicateContent = async (data) => {
      const existLang = {}

      allItems.value.forEach((value) => {
        const langValue = value.lang
        const translates = value.brand_translates
        const titleObj = translates.find((item) => item.attribute_code === 'brand_name')
        
        if (titleObj.value === data.brand_name || data.brand_lang_id === value.brand_lang_id) existLang[langValue] = 1
      })

      const keyLang = Object.keys(existLang)
      hiddenLang.value = !isEmpty(keyLang) ? keyLang : []
      crudFunction.duplicateContent(data.id)
    }

    return {
      ...crudFunction,
      cldUpload,
      items,
      duplicateContent,
      handlerDuplicateContent,
      updatedAtDatetimeFormat,
      get,
      hiddenLang,
      editData,
      searchKeys: ['logo', 'brand_name', 'brand_concept']
    }
  },
}
</script>

<style lang="scss">
  .brand-logo {
    height: 80px;
    width: 150px;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  .brand-name {
    span {
      overflow: hidden;
      white-space:nowrap;
      text-overflow:ellipsis;
      max-width: 300px;
      display:inline-block;
    }
  }
  .brand-concept {
    span {
      overflow: hidden;
      white-space:nowrap;
      text-overflow:ellipsis;
      max-width: 600px;
      display:inline-block;
    }
  }
</style>
